import styled from 'styled-components';
import { styles } from '../styles';

export const P = styled.p`
  font-size: 15px;
  font-family: ${styles.defaultFont};
  line-height: 26px;
`;

export const A = styled.a`
  color: #4a90e2;
  text-decoration: none;
`;

export const CounterP = styled(P)`
  color: #9298a6;
  font-size: 16px;
  position: relative;
  top: 11px;
`;

export const CounterPError = styled(CounterP)`
  color: ${styles.errorColor};
`;
