const black = '#161E2E';
const red = '#EE5253';
const blue = '#1E86FF';
const grey = '#6B7280';
const paleBlue = '#374151';

export const styles = {
  defaultFont: `src: url('../../public/fonts/Inter-Regular.ttf')`,
  defaultFontMedium: `src: url('../../public/fonts/Inter-Medium.ttf')`,
  defaultFontSemiBold: `src: url('../../public/fonts/Inter-SemiBold.ttf')`,
  defaultFontExtraBold: `src: url('../../public/fonts/Inter-ExtraBold.ttf')`,
  defaultFontBlack: `src: url('../../public/fonts/Inter-Black.ttf')`,
  errorColor: red,
  linkColor: blue,
  textColor: black,
  unfocusedTextColor: grey,
  labelColor: paleBlue,
  mobileWidth: '900px'
};
