import Image from 'next/image';
import * as React from 'react';

import styled from 'styled-components';

export const Thumbnail = (props: {
    image: string;
    isWide?: Boolean
}) =>
  props.image ? (
    props.isWide ? (
      <WideImage src={`${props.image}?fit=crop`} alt='Thumbnail Image' />
    ) : (
      <StyledImage
        src={`${props.image}?crop=faces&h=50&w=50&fit=crop&mask=ellipse`}
        alt='Thumbnail Image'
      />
    )
  ) : (
    <DummyImage />
  );

const DummyImage = styled.div`
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  background-color: #e2e6ef;
  border-radius: 50%;
`;

const StyledImage = styled(Image)`
  flex-shrink: 0;
  width: 50px;
  height: 50px;
`;

const WideImage = styled(Image)`
  object-fit: cover;
  border-radius: 50%;
  width: 50px;
  height: 50px;
`;
