import * as React from 'react';

import { BlockError } from './BlockError';

import { InlineError } from './InlineError';
import styled from 'styled-components';
import { ListError } from './ListError';
import { FormErrorsProps } from './FormErrorsProps';
import { Spacer } from '../../general';


export const FormErrors = (props: FormErrorsProps) => {
  const displayComps = {
    inline: InlineError,
    block: BlockError,
    list: ListError
  };

  const FormError = displayComps[props.displayType];

  const errors =
    props.errors && Array.isArray(props.errors) ? props.errors : [];

  return (
    <>
      {props.displayType === 'inline' ? (
        <InlineContainer>
          <FormError>{errors[0]}</FormError>
        </InlineContainer>
      ) : props.displayType === 'list' ? (
        <ListContainer>
          {errors.map((error, key) => (
            <FormError key={key}>{error}</FormError>
          ))}
        </ListContainer>
      ) : (
        <BlockContainer>
          {errors.map((error, key) => (
            <FormError key={key}>{error}</FormError>
          ))}
          {!!errors.length && <Spacer height={12} />}
        </BlockContainer>
      )}
    </>
  );
};

const InlineContainer = styled.div`
  & > div {
    position: absolute;
    margin-top: 5px;
    font-size: 12px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
`;
const ListContainer = styled.div`
  & > div {
    margin-top: 5px;
    font-size: 12px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

const BlockContainer = styled.div`
  & > div {
    margin-top: 13px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
`;
