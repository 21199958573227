import * as React from 'react';
import { FieldPropTypes } from './FieldTypes';
import { Label } from '../Label';
import styled from 'styled-components';
import { FormErrors } from '../FormErrors';
import { styles } from '../../styles';
import { FlexCol } from '../../Layout';
import { PLight } from '../../typography';

export const Field = (props: FieldPropTypes) => {
  const hasErrors = Array.isArray(props?.errors);

  return (
    <FieldContainer
      data-testid='fieldset'
      className={hasErrors ? 'error' : ''}
      style={{
        flex: props.flex ? 1 : undefined,
        width: props.width
          ? typeof props.width === 'number'
            ? `${props.width}px`
            : props.width
          : '100%',
        ...props.style
      }}
    >
      {props.label && (
        <Label
          style={{
            paddingBottom: props.note ? '0' : undefined,
            paddingLeft: props.paddingLeft
              ? `${props.paddingLeft}px`
              : undefined,
              ...props.style
          }}
        >
          <FlexCol>
            {props.label}
            <PLight>{props.note}</PLight>
          </FlexCol>
        </Label>
      )}
      {props.children}
      {!props.suppressErrorMessages && props.errors && props.errors.length ? (
        <FormErrors
          errors={props.errors}
          displayType={props.errorDisplayType || 'inline'}
        />
      ) : null}
    </FieldContainer>
  );
};

const FieldContainer = styled.div`
  position: relative;
  &.error {
    .inputContainer {
      border-color: ${styles.errorColor};
      border-width: 2px;
    }
  }
  .MuiFormHelperText-root {
    min-height: 0em;
    margin: 0;
  }
`;
