import styled from 'styled-components';

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
`;

export const Right = styled.div`
  margin-left: auto;
`;

export const Left = styled.div`
  margin-right: auto;
`;

export const FlexRowWrap = styled(FlexRow)`
  flex-wrap: wrap;
`;

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const FlexRowAlign = styled(FlexRow)`
  align-items: center;
`;

export const FlexRowEnd = styled(FlexRow)`
  align-items: flex-end;
`;

export const FlexRowBetween = styled(FlexRowAlign)`
  justify-content: space-between;
`;

export const FlexEnd = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const FlexSink = styled(FlexEnd)`
  flex-direction: column;
  height: 100%;
`;

export const Flex = styled.div`
  flex: 1;
`;

export const FlexRowCenter = styled(FlexRow)`
  justify-content: center;
`;
