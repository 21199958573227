export const defaultTheme = {
    // General Theme
    navBarBackgroundColor: '#FFFFFF',
    mainBackgroundColor: '#FFFFFF',
    navBarIconColor: '#1C1C1C',
    headerBackgroundColor: '#FAFAFA',
    primaryButtonBackgroundColor: '#1E86FF',
    primaryButtonTextStyle: {
      color: '#FFFFFF'
    },
    secondaryButtonBackgroundColor: '#FFFFFF',
    secondaryButtonOutline: '#1E86FF',
    secondaryButtonTextStyle: {
      color: '#1E86FF'
    },
    footerNavBarBackgroundColor: '#FAFAFA',
    footerNavLabelSelectedTextStyle: {
      color: '#1C1C1C'
    },
    footerNavLabelUnselectedTextStyle: {
      color: '#8D8B8B'
    },
    eventTileBackgroundColor: '#FFFFFF',
    showEventTileBoxShadow: true,
    ticketTypeHeadingBackgroundColor: '#1E86FF',
    ticketTypeHeadingTextStyle: {
      color: '#FFFFFF'
    },
    dividerColor: '#FAFAFA',
    header1TextStyle: {
      color: '#1C1C1C'
    },
    header2TextStyle: {
      color: '#1C1C1C'
    },
    header3TextStyle: {
      color: '#1C1C1C'
    },
    subtitleTextStyle: {
      color: '#1C1C1C'
    },
    paragraph1TextStyle: {
      color: '#1C1C1C'
    },
    paragraph2TextStyle: {
      color: '#1C1C1C'
    },
    linkTextStyle: {
      color: '#1E86FF'
    },
    successMessageTextStyle: {
      color: '#02B140'
    },
    logoutLinkTextStyle: {
      color: '#1E86FF'
    },
    eventCategoryDropdownTextStyle: {
      color: '#1C1C1C'
    },
    headerNavLabelSelectedTextStyle: {
      color: '#1C1C1C'
    },
    headerNavLabelUnselectedTextStyle: {
      color: '#1C1C1C'
    },
    // Splash Page Theme
    splashPageBackgroundColor: '#FFFFFF',
    eventsButtonColorSelected: '#1E86FF',
    eventsButtonColorUnselected: '#9CA0AC',
    eventsButtonSelectedTextStyle: {
      color: '#FFFFFF'
    },
    eventsButtonUnselectedTextStyle: {
      color: '#FFFFFF'
    },
    splashPageLinkTextStyle: {
      color: '#1E86FF'
    },
    disclaimerTextStyle: {
      color: '#9CA0AC'
    },
    disclaimerLinkTextStyle: {
      color: '#1E86FF'
    },
    splashPageHeaderTextStyle: {
      color: '#1C1C1C'
    },
    splashPageSubtitleTextStyle: {
      color: '#9CA0AC'
    },
    formFieldOutlineColor: '#9CA0AC',
    formFieldFillColor: '#FFFFFF',
    formFieldHintTextStyle: {
      color: '#9CA0AC'
    },
    formFieldTextStyle: {
      color: '#1C1C1C'
    }
  };