import { request } from '@/api';
import { CartType } from '@/types/cartTypes';
import * as React from 'react';
import { omit } from 'ramda';

const UPDATE_CART_ITEMS = require('../../graphql/mutations/UPDATE_CART_ITEMS.gql');

export async function updateCart(
  id: string,
  appId: string,
  newCart: CartType,
  setCart: React.SetStateAction<any>,
  refetchQuery: Function,
  appName: string,
  setCartTime: Function,
  appCartTime: number
) {
  try {
    const updatedCartRes = await request(UPDATE_CART_ITEMS, {
      id,
      appId,
      appName,
      input: omit(['date'], newCart)
    });
    const updatedCartData = updatedCartRes.data.updateCartItems;
    if (updatedCartData.success) {
      const updatedCart = updatedCartData.cart;
      const date = updatedCart?.date
      const now = Math.round(Date.now() / 1000)
      const addedTime = appCartTime * 60
      setCart(updatedCart);
      setCartTime(date + addedTime - now)
      await refetchQuery();
      return updatedCart;
    } else throw new Error(updatedCartData.error);
  } catch (error) {
    return { error };
  }
}
