export const getConfig = async (appId: string, setConfig?: Function) => {
  const res = await fetch(process.env.LIVEORDER_GET_CONFIG_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      appId: appId
    })
  });
  const data = await res.json();
  if (res.status === 200) {
    setConfig(data);
    return data;
  } else {
    console.warn(data);
    return;
  }
};
