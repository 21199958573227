import { request } from '@/api';
import { translateForm } from './translateEntity';
import { defaultTheme } from './defaultTheme';

const LIST_APP_TRANSLATIONS = require('../graphql/queries/LIST_APP_TRANSLATIONS.gql');
const LIST_DEFAULT_TRANSLATIONS = require('../graphql/queries/LIST_DEFAULT_TRANSLATIONS.gql');

export async function getStoreTranslations(
  appId: string,
  browserLocale: string,
  setStoreTranslations: React.SetStateAction<any>,
  setDefaultLocale: React.SetStateAction<any>,
  setApp: React.SetStateAction<any>,
) {
  const data = await request(LIST_APP_TRANSLATIONS, {
    appId
  });
  const appTheme = data?.data?.getApp?.theme;
  const appForms = data?.data?.getApp?.listAppForms?.items;
  const storeForms = translateForm(appForms, browserLocale);
  let theme = {};
  Object.keys(appTheme).forEach(key => {
      theme[key] = !!appTheme[key] || appTheme[key] === false ? appTheme[key] : defaultTheme[key]
  })
  const app = {
    ...data?.data?.getApp,
    theme,
    listAppForms: {
      items: storeForms
    }
  };
  
  setApp(app)
  setDefaultLocale((data?.data?.getApp?.settings?.supportedLanguages || ['en'])[0])
  const defaultData = await request(LIST_DEFAULT_TRANSLATIONS, {
    appId
  });
  const translations = data?.data?.getApp?.listAppTranslations?.items;
  const defaultTranslations = defaultData?.data?.listDefaultTranslations?.items;
  const appTranslations = translations?.find(
    (translation) => translation.locale === browserLocale
  );
  const defaultAppLocaleTranslations = translations?.find(
    (translation) =>
      translation.locale ===
      (data?.data?.getApp?.settings?.supportedLanguages || ['en'])[0]
  );
  const defaultLocaleTranslations = defaultTranslations?.find(
    (translation) => translation.locale === browserLocale
  );
  const defaultEnglishTranslations = defaultTranslations?.find(
    (translation) => translation.locale === 'en'
  );
  let storeTranslations;
  const mergedTranslations = defaultEnglishTranslations.translations.map(
    (translation) => {
      const updatedTranslation = { ...translation };
      const appTranslationValue = appTranslations?.translations?.find(
        (appTranslation) => appTranslation.key === translation.key
      )?.value;
      const defaultLocaleValue = defaultLocaleTranslations?.translations?.find(
        (defaultTranslation) => defaultTranslation.key === translation.key
      )?.value;
      const defaultAppLocaleValue =
        defaultAppLocaleTranslations?.translations?.find(
          (defaultTranslation) => defaultTranslation.key === translation.key
        )?.value;

      updatedTranslation.value =
        appTranslationValue ||
        defaultLocaleValue ||
        defaultAppLocaleValue ||
        translation.value;
      return updatedTranslation;
    }
  );
  if (appTranslations) {
    storeTranslations = {
      ...appTranslations,
      translations: mergedTranslations
    };
  } else {
    storeTranslations = {
      ...(defaultLocaleTranslations ||
        defaultAppLocaleTranslations ||
        defaultEnglishTranslations),
      translations: mergedTranslations
    };
  }
  return setStoreTranslations(storeTranslations);
}
