import { delay } from '@/utils/delay';
import * as React from 'react';
import styled from 'styled-components';
import { Input } from '../../form';
import { P } from '../../typography';
import { FlexCol, FlexRow } from '../Layout';
import { AppData } from '@/types/loginTypes';

const { useState } = React;

export const IncrementalInput = (props: {
  value: number;
  onValueChange: Function;
  limit?: number;
  margin?: string;
  disabled?: Boolean;
  setIsSaving?: React.SetStateAction<any>;
  step?: number;
  minimum?: number;
  eventLimitReached: boolean;
  setWarning?: React.SetStateAction<any>;
  app?: AppData
}) => {

  const [error, setError] = useState('');
  return (
    <FlexCol>
      <Container margin={props.margin}>
        <FlexRow>
          <MinusButton
            disabled={props.value === props.minimum || props.disabled}
            onClick={async () => {
              if(props.disabled) return;
              if (props.setIsSaving) {
                props.setIsSaving(true);
              }
              const increment = props.step ? props.step : 1;
              const minimum = props.minimum || 0;
              if (props.value > 0 && props.value - increment >= minimum)
                await props.onValueChange(props.value - increment);
              else if (props.value > 0 && props.value - increment < minimum) {
                await props.onValueChange(minimum);
              } else if (isNaN(props.value)) props.onValueChange(minimum);
              if (props.setIsSaving) {
                props.setIsSaving(false);
              }
            }}
          >
            -
          </MinusButton>
          <Input
            onKeyPress={(e) => {
              const value = e.key;
              if (isNaN(parseInt(value))) {
                e.preventDefault();
              }
            }}
            readOnly={!!props.disabled}
            onValueChange={async (value) => {
              if (props.setIsSaving) {
                props.setIsSaving(true);
              }
              const parsedValue = parseInt(value);
              if (props.step && parsedValue % props.step !== 0) {
                setError(
                  `Quantity requests for this item must be a factor of ${props.step}.`
                );
                await delay(10);
                setError('');
              } else if (isNaN(parsedValue)) {
                await props.onValueChange(0);
              } else if (props.minimum && parsedValue < props.minimum) {
                setError(
                  `The quantity requested (${parsedValue}) is less than the minimum limit (${props.minimum}) for this item.`
                );
                await delay(10);
                setError('');
              } else if (props.limit >= 0 && parsedValue > props.limit) {
                setError(
                  `The quantity requested (${parsedValue}) is greater than the limit (${props.limit}) for this item.`
                );
                await delay(10);
                setError('');
              } else if (parsedValue <= props.limit || !props.limit) {
                await props.onValueChange(parsedValue);
              }
              if (props.setIsSaving) {
                props.setIsSaving(false);
              }
            }}
            hideVerticalPadding
            value={props.value}
            min={0}
            style={{
              maxWidth: '100px',
              borderRadius: '0px',
              height: '56px'
            }}
          />
          <PlusButton
            disabled={
              props.value === props.limit ||
              props.disabled ||
              props.limit <= 0 ||
              props.eventLimitReached ||
              props.step > props.limit - props.value
            }
            onClick={async () => {
              if (props.eventLimitReached && props.setWarning) return props.setWarning(true)
              if(props.disabled) return;
              if (props.setIsSaving) props.setIsSaving(true);
              if (!props.eventLimitReached) {
                const increment = props.step ? props.step : 1;
                if (
                  typeof props.value === 'number' &&
                  (props.value + increment <= props.limit || !props.limit)
                )
                  await props.onValueChange(props.value + increment);
                else if (isNaN(props.value)) await props.onValueChange(0);
              }
              if (props.setIsSaving) props.setIsSaving(false);
            }}
          >
            +
          </PlusButton>
        </FlexRow>
      </Container>
      {error && <P style={{ color: '#FF0000' }}>{error}</P>}
    </FlexCol>
  );
};

const MinusButton = styled.div<{ disabled: Boolean }>`
  padding: 17px;
  border-top: 1px #d8d8d8 solid;
  border-bottom: 1px #d8d8d8 solid;
  border-left: 1px #d8d8d8 solid;
  border-radius: 6px 0px 0px 6px;
  &:hover {
    border: ${(props) => (!props.disabled ? '1px #000000 solid' : '')};
  }
  font-size: 16px;
  margin: auto;
  background-color: ${(props) => (props.disabled ? '#f2f3f4' : '#ffffff')};
  cursor: ${(props) => (!props.disabled ? 'pointer' : '')};
  color: ${(props) => (props.disabled ? 'grey' : 'black')};
`;

const PlusButton = styled.div<{ disabled: Boolean }>`
  padding: 17px;
  border-top: 1px #d8d8d8 solid;
  border-bottom: 1px #d8d8d8 solid;
  border-right: 1px #d8d8d8 solid;
  border-radius: 0px 6px 6px 0px;
  &:hover {
    border: ${(props) => (!props.disabled ? '1px #000000 solid' : '')};
  }
  font-size: 16px;
  margin: auto;
  background-color: ${(props) => (props.disabled ? '#f2f3f4' : '#ffffff')};
  cursor: ${(props) => (!props.disabled ? 'pointer' : '')};
  color: ${(props) => (props.disabled ? 'grey' : 'black')};
`;

const Container = styled.div<{ margin?: string }>`
  width: fit-content;
  margin: ${(props) => props.margin ?? ''};
`;
