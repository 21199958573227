import * as React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1E86FF',
      contrastText: '#fff'
    },
    secondary: {
      main: '#6f7287',
      contrastText: '#1E86FF'
    },
    error: {
      main: '#F8001F',
      contrastText: '#FFF'
    }
  }
});

export const withTheme = (Cmp) => (props) => (
  <ThemeProvider theme={theme}>
    <Cmp {...props} />
  </ThemeProvider>
);