import { request } from '@/api';
import { CartType } from '@/types/cartTypes';
import React from 'react';
import { removeCart } from './removeCart';

const GET_USER_CARTS = require('../../graphql/queries/GET_USER_CARTS.gql');

export async function getUserCart(
  userId: string,
  appId: string,
  appName: string,
  setCart?: React.SetStateAction<any>,
  showCart?: Boolean
) {
  const cartRes = await request(GET_USER_CARTS, {
    userId,
    appId
  });
  const cartData =
    (cartRes?.data?.listUserCarts?.items?.[0] as CartType) ?? undefined;

  if (cartData) {
    const cartTimer = cartRes?.data?.getApp?.settings?.cartTimer;
    const now = Date.now() / 1000;
    if (cartData.date < now - cartTimer * 60 && !showCart) {
      removeCart(cartData.id, appId, appName);
      return undefined;
    } else if (setCart) {
      localStorage.removeItem('cartSessionId');
      setCart(cartData);
      return cartData;
    }
  }
  return cartData;
}
