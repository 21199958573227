import styled from 'styled-components';
import { styles } from '../../styles';

export const BlockError = styled.div`
    background-color: #D0021B;
    color: #FFF;
    font-family: ${styles.defaultFont};
    font-size: 14px;
    background-image: url(/images/icons/exclamation.svg);
    background-repeat: no-repeat;
    background-position: 14px 10px;
    padding: 12px 12px 12px 46px;
`;