import styled from 'styled-components';

export const Title = styled.p`
  font-family: Inter;
  font-weight: bold;
  font-size: 36px;
  line-height: 36px;
  padding-right: 9px;
`;

export const SubTitle = styled.p`
  font-family: Inter;
  color: #7a7a7c;
  line-height: 28px;
  font-weight: 500;
  font-size: 22px;
`;

export const P = styled.p`
  font-family: Inter;
  line-height: 29px;
  font-weight: 400;
  font-size: 22px;
`;

export const PLight= styled.p`
  font-family: Inter;
  line-height: 32px;
  font-weight: 400;
  color: #7a7a7c;
  font-size: 18px;
`;
