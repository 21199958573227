import * as React from 'react';
import styled from 'styled-components';
import { styles } from '../../styles';
import { LabelProps } from './LabelProps';

export const Label = (props: LabelProps) => (
  <LabelView data-testid='fieldLabel' style={props.style}>
    {props.children}
  </LabelView>
);

const LabelView = styled.label`
  font-family: ${styles.defaultFontMedium};
  font-size: 15px;
  padding-bottom: 12px;
  padding-top: 24px;
  display: block;
  align-items: center;
  display: flex;
  color: ${styles.labelColor};
`;
