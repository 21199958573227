import { request } from '@/api';
import { getUserCart } from './getUserCart';
import { removeCart } from './removeCart';

const ASSIGN_CART_TO_USER = require('../../graphql/mutations/ASSIGN_CART_TO_USER.gql');

export async function assignCartToUser(
  cartId: string,
  appId: string,
  userId: string,
  setCart: Function,
  appName: string
) {
  const existingUserCart = await getUserCart(userId, appId, appName);
  if (existingUserCart) {
    await removeCart(existingUserCart.id, existingUserCart?.appId, appName);
  }
  const cartData = await request(ASSIGN_CART_TO_USER, {
    id: cartId,
    appId,
    userId
  });
  const cart = cartData?.data?.assignCartToUser;
  localStorage.removeItem('cartSessionId');
  setCart(cart);
  return;
}
