import { Amplify } from 'aws-amplify';

import {
  AWS_REGION,
} from '../src/constants';



export const passwordlessAmplifyConfig = {
  Auth: {
    userPoolId: process.env.PASSWORDLESS_POOL_ID,
    region: AWS_REGION,
    userPoolWebClientId: process.env.PASSWORDLESS_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    mandatorySignIn: true,
    identityPoolId: '',
    identityPoolRegion: 'us-east-1'
  },
  aws_appsync_graphqlEndpoint: process.env.LIVEORDER_APPSYNC_GRAPHQL_ENDPOINT,
  aws_appsync_region: AWS_REGION,
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: process.env.LIVEORDER_APPSYNC_API_KEY,
  graphql_endpoint: process.env.LIVEORDER_APPSYNC_GRAPHQL_ENDPOINT
};
Amplify.configure(passwordlessAmplifyConfig);