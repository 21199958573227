import * as React from 'react';
import { FormPropTypes } from './FormTypes';

export const Form = (props: FormPropTypes) => (
  <form
    style={props.style}
    data-testid='form'
    noValidate={true}
    onSubmit={(e) => {
      e.preventDefault();

      if (props.onSubmit) {
        props.onSubmit(e);
      }
    }}
  >
    {props.children}
  </form>
);
