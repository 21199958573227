import * as React from 'react';
import styled from 'styled-components';
import { P } from '../../mobile';

const BannerMap = {
  green: {
    backgroundColor: '#10AC84',
    color: '#fff'
  },
  grey: {
    backgroundColor: '#ECEDF1',
    color: '#7E8085'
  },
  yellow: {
    backgroundColor: '#F7B731',
    color: '#fff'
  },
  red: {
    backgroundColor: '#EE5253',
    color: '#fff'
  }
};

// This needs to be in a parent div container with absolute positioning
// with the image you are trying to display banner over

export function EventBanner(props: {
  color: string;
  borderRadius?: string;
  text: string;
}) {
  const color = BannerMap?.[props.color];
  return (
    <Banner
      color={color?.color}
      borderRadius={props.borderRadius}
      backgroundColor={color?.backgroundColor}
    >
      <EventBannerText>{props.text}</EventBannerText>
    </Banner>
  );
}

const EventBannerText = styled(P)`
  align-self: center;
  font-weight: 500;
`;

const Banner = styled.span<{
  color: string;
  backgroundColor: string;
  borderRadius?: string;
  bottom?: string;
}>`
  color ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
  border-radius: ${(props) => props.borderRadius ?? '0px 0px 6px 6px'};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  height: 30px;
  width: 100%;
  position: absolute;
  bottom: 0;
`;
