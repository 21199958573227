import type { AppProps } from 'next/app';
import { Auth } from 'aws-amplify';
import * as React from 'react';
import '@/styles/globals.css';
import * as Sentry from '@sentry/nextjs';
import '@/styles/phoneInput.css';
import 'react-phone-input-2/lib/style.css';
import { AppData } from '../types/loginTypes';
import Head from 'next/head';
import { ActiveUserContext } from '../context/ActiveUser';
import {
  AppWrapper,
  ErrorBoundary,
  StateManager
} from '../features/@components/general';
import { CartType } from '@/types/cartTypes';
import { CartContext } from '@/context/Cart';
import { getCart, getUserCart, removeCart } from '@/utils/cart';
import {
  getAppBySlug,
  getConfig,
  useIsMobile,
  IsSsrMobileContext
} from '@/utils';
import { passwordlessAmplifyConfig } from '@/passwordlessAmplifyConfig';
import { getIsSsrMobile } from '@/utils/getIsSsrMobile';
import { GetServerSidePropsContext } from 'next';
import dynamic from 'next/dynamic';
import { getStoreTranslations } from '@/utils/getAppTranslations';
import { TranslationsContext } from '@/context/Translations';

//  --- Dynamic Import of certain componenets to save time on intialization ----
// https://nextjs.org/docs/pages/building-your-application/optimizing/lazy-loading
const Cart = dynamic(() => import('../features/Cart/index'));
const NavBarMobile = dynamic(
  () => import('../features/@components/navigation/NavBarMobile')
);
const NavBar = dynamic(
  () => import('../features/@components/navigation/NavBar')
);
const Footer = dynamic(
  () => import('../features/@components/navigation/Footer')
);

const { useEffect, useState } = React;

Auth.configure(passwordlessAmplifyConfig);

export default function App({ Component, pageProps, router }: AppProps) {
  const [config, setConfig] = useState(null as any);
  const isMobile = useIsMobile();
  const [navTitle, setNavTitle] = useState('' as string);
  const [passwordGateOpen, setPasswordGateOpen] = useState(false as boolean);
  const [accountScreen, setAccountScreen] = useState('' as string);
  const [eventId, setEventId] = useState('' as string);
  const [ticketEvent, setTicketEvent] = useState(
    {} as { id: string; ticketCount: number }
  );
  const slugName = router.query.appId as string;
  const [existingUser, setExistingUser] = useState(false as boolean);
  const [user, setUser] = useState({
    username: '',
    email: '',
    phoneNumber: '',
    firstName: '',
    lastName: ''
  } as any);
  const [cart, setCart] = useState(undefined as undefined | CartType);
  const [cartTime, setCartTime] = useState(undefined as undefined | number);
  const [showCart, setShowCart] = useState(false as boolean);
  const [skipSignIn, setSkipSignIn] = useState(undefined as boolean);
  const [loading, setLoading] = useState(false as boolean);
  const [appId, setAppId] = useState('' as string);
  const appName = config?.liveSalesName;
  const [storeTranslations, setStoreTranslations] = useState({} as any);
  const [defaultLocale, setDefaultLocale] = useState(undefined as string | undefined)
  const [app, setApp] = useState({} as AppData)

  useEffect(() => {
    ticketEvent.id && ticketEvent.id !== eventId && setEventId(ticketEvent.id);
  }, [ticketEvent]);
  let window;

  useEffect(() => {
    setLoading(true);
    if (typeof window !== undefined) {
      if (slugName) {
        getAppBySlug(slugName, setAppId);
      }
    }
    setLoading(false);
  }, [window, user, slugName, appId]);

  useEffect(() => {
    setLoading(true);
    if (appId && typeof appId === 'string' && !config) {
      getConfig(appId, setConfig);
      const browserLanguage = (navigator.language)?.split('-')[0];
      getStoreTranslations(appId, browserLanguage, setStoreTranslations, setDefaultLocale, setApp);
    }
    setLoading(false);
  }, [appId]);

  const updateUser = async (res) => {
    const authorization = res?.signInUserSession?.accessToken?.jwtToken;
    localStorage.setItem('authorization', authorization);

    const user = {
      ...res.attributes,
      firstName: res.attributes.given_name,
      lastName: res.attributes.family_name,
      phoneNumber: res.attributes.phone_number,
      username: res.attributes.sub
    };
    Sentry.setUser({
      email: res.attributes.email,
      id: res.attributes.sub,
      username: `${user.firstName} ${user.lastName}`
    });
    setUser(user);
  };

  useEffect(() => {
    setLoading(true);
    if (typeof window !== undefined) {
      if (localStorage.getItem('authorization') === 'guest') {
        if (!user.phoneNumber) {
          setUser({
            phoneNumber: 'guest'
          });
        }
        const cartSessionId = localStorage.getItem('cartSessionId');
        if ((!user || user.phoneNumber === 'guest') && cartSessionId && appId) {
          getCart(cartSessionId, appId, showCart, config?.setCart);
        }
      } else if (localStorage.getItem('formIncomplete') !== 'true') {
        Auth.currentAuthenticatedUser()
          .then((res) => {
            if (!user.phoneNumber) {
              updateUser(res);
            }
            if (!cart && user.username && appId) {
              getUserCart(user.username, appId, appName, setCart, showCart);
            }
          })
          .catch(() => {});
      }
    }
    setLoading(false);
  }, [window, user, appId]);

  useEffect(() => {
    setSkipSignIn(!isMobile || config?.skipInitialLogin);
  }, [isMobile, config]);

  useEffect(() => {
    if (skipSignIn !== undefined) {
      if (config && !config?.isOpen) {
        router.push(`/${slugName}/comingSoon`);
      } else if (config?.requirePassword && !passwordGateOpen) {
        router.push(`/${slugName}/passwordGate`);
      } else if (
        (!user.phoneNumber || user.phoneNumber === 'guest') &&
        !skipSignIn &&
        slugName &&
        config?.isOpen
      ) {
        router.push(`/${slugName}/login`);
      }
    }
  }, [user, slugName, skipSignIn, passwordGateOpen, router.asPath, config]);

  useEffect(() => {
    const cartDate = cart?.date;
    const now = Math.round(Date.now() / 1000);
    const addedTime = config?.cartTimer * 60;
    if (!cart) {
      setCartTime(undefined);
    } else if (cart && cartTime) {
      let myInterval = setInterval(() => {
        if (cartTime > 0) {
          setCartTime(cartTime - 1);
        }
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    } else if (cartTime === undefined && cart && config) {
      setCartTime(cartDate + addedTime - now);
    } else if (cartTime <= 0 && cart && cartDate > 0 && config) {
      removeCart(cart.id, appId, appName, setCart);
      setCartTime(undefined);
    }
    return () => {
      null;
    };
  }, [cart, cartTime, config]);

  const hideNavBar: boolean =
    router.route.includes('comingSoon') ||
    router.route.includes('login') ||
    router.route.includes('password');

  return (
    <>
      <Head>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1, viewport-fit=cover, shrink-to-fit=no'
        />
      </Head>
      <ActiveUserContext.Provider value={{ user, setUser }}>
        <IsSsrMobileContext.Provider value={pageProps.isSsrMobile}>
          <TranslationsContext.Provider value={{translations: storeTranslations, setTranslations: setStoreTranslations}}>
          <CartContext.Provider value={{ cart, setCart }}>
            <StateManager loading={loading}>
            <style jsx global>{`
              body {
                background: ${app?.theme?.mainBackgroundColor};
              }
            `}</style>
              <ErrorBoundary>
                {config ? (
                  <>
                    {!hideNavBar && (
                      <>
                        {isMobile ? (
                          <NavBarMobile
                            appId={appId}
                            slugName={slugName}
                            navTitle={navTitle}
                            setNavTitle={setNavTitle}
                            setAccountScreen={setAccountScreen}
                            showCart={showCart}
                            setShowCart={setShowCart}
                            eventId={eventId}
                            setEventId={setEventId}
                            setTicketEvent={setTicketEvent}
                            cart={cart}
                            app={{ ...config, ...app, name: appName } as AppData}
                          />
                        ) : (
                          <NavBar
                            appId={appId}
                            slugName={slugName}
                            showCart={showCart}
                            setShowCart={setShowCart}
                            setTicketEvent={setTicketEvent}
                            cart={cart}
                            app={{ ...config, ...app, name: appName } as AppData}
                          />
                        )}
                      </>
                    )}
                    <AppWrapper noPadding={hideNavBar}>
                      {showCart && isMobile ? (
                        <Cart
                          appId={appId}
                          isMobile={isMobile}
                          slugName={slugName}
                          showCart={showCart}
                          setShowCart={setShowCart}
                          setSkipSignIn={setSkipSignIn}
                          setUser={setUser}
                          cartTime={cartTime}
                          setCartTime={setCartTime}
                          app={app}
                        />
                      ) : (
                        <>
                          {showCart && (
                            <Cart
                              appId={appId}
                              isMobile={isMobile}
                              showCart={showCart}
                              slugName={slugName}
                              setShowCart={setShowCart}
                              setSkipSignIn={setSkipSignIn}
                              setUser={setUser}
                              cartTime={cartTime}
                              setCartTime={setCartTime}
                              app={app}
                            />
                          )}
                          <Component
                            {...pageProps}
                            appId={appId}
                            defaultLocale={defaultLocale}
                            storeTranslations={storeTranslations}
                            setUser={setUser}
                            setSkipSignIn={setSkipSignIn}
                            slugName={slugName}
                            setNavTitle={setNavTitle}
                            accountScreen={accountScreen}
                            setAccountScreen={setAccountScreen}
                            ticketEvent={ticketEvent}
                            setTicketEvent={setTicketEvent}
                            showCart={showCart}
                            setExistingUser={setExistingUser}
                            setShowCart={setShowCart}
                            app={{ ...config, ...app, name: appName } as AppData}
                            user={user}
                            existingUser={existingUser}
                            setPasswordGateOpen={setPasswordGateOpen}
                            passwordGateOpen={
                              passwordGateOpen || !config?.requirePassword
                            }
                            setCartTime={setCartTime}
                            setApp={setApp}
                          />
                        </>
                      )}
                    </AppWrapper>
                    {isMobile && !hideNavBar && (
                      <Footer
                        setShowCart={setShowCart}
                        appId={appId}
                        setNavTitle={setNavTitle}
                        slugName={slugName}
                        setTicketEvent={setTicketEvent}
                        setEventId={setEventId}
                        setAccountScreen={setAccountScreen}
                        app={{ ...config, ...app, name: appName } as AppData}
                      />
                    )}
                  </>
                ) : null}
              </ErrorBoundary>
            </StateManager>
          </CartContext.Provider>
          </TranslationsContext.Provider>
        </IsSsrMobileContext.Provider>
      </ActiveUserContext.Provider>
    </>
  );
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  return {
    props: {
      isSsrMobile: getIsSsrMobile(context)
    }
  };
}
