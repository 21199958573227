import styled from 'styled-components';
import { styles } from '../styles';

export const PLight = styled.p`
  color: #9CA0AC;
  font-size: 15px;
  font-family: ${styles.defaultFont};
  line-height: 26px;
`;

export const PLightPlaceholder = styled.p`
  color: #9fa6b3;
  font-size: 16px;
  font-family: ${styles.defaultFont};
  line-height: 26px;
`;

export const PLightInline = styled(PLight)`
  display: inline;
`;

export const PLightLabel = styled.p`
  color: #6f7287;
  font-size: 12px;
  font-family: Inter-Regular;
`;
