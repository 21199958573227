import React from 'react';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';


const StyledTextField = styled(TextField)<any>(({ outlineColor, style, InputLabelProps }) => ({
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: outlineColor,
    },
    '&.Mui-focused fieldset': {
      borderColor: outlineColor,
    }
  },
  borderRadius: '5px',
  ...style,
  ...InputLabelProps?.style
}));

const CustomTextField: React.FC<any> = (props) => {
  const { outlineColor, } = props;
  return <StyledTextField outlineColor={outlineColor} {...props}  />;
};

export default CustomTextField;
