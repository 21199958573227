import * as React from 'react';
import { SpacerTypes } from './SpacerTypes';

export const Spacer = (props: SpacerTypes) => (
    <div 
        style={{
            minWidth: `${props.width || 1}px`,
            minHeight: `${props.height || 1}px`
        }} 
    />
);