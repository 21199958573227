import * as React from 'react';
import { curry, lensPath, path, set } from 'ramda';
import { GraphQLClient } from 'graphql-request';

const { useEffect, useState } = React;

export const request = curry(
  (QUERY: string, variables?: any): Promise<any> => { // I have just used any here as a quick fix because we need to do a prod deployment . If you know the right type please use that. Sorry for making this Dirty! : Abhinav! 
    const client = new GraphQLClient(
      process.env.LIVEORDER_APPSYNC_GRAPHQL_ENDPOINT as string,
      {
        headers: {
          'x-api-key': process.env.LIVEORDER_APPSYNC_API_KEY as string
        }
      }
    );
    return client.request(QUERY, variables).then((res: any) => {
      return Promise.resolve({
        data: res,
        loading: false,
        error: null
      });
    });
  }
);

export const useRequest: (
  QUERY: any,
  variables?: Object
) => {
  data: any;
  loading: boolean;
  error: any;
  refetchQuery: () => void;
} = (QUERY, variables) => {
  const [response, setResponse] = useState({
    loading: true,
    error: null,
    data: null as null | any
  });

  const setError = (error) => {
    setResponse({
      data: error.data,
      loading: false,
      error
    });
  };

  const doQuery = (_nextToken?: string | null, _previousResponse?: any) => {
    request(QUERY, variables).then(setResponse).catch(setError);
  };

  useEffect(() => doQuery(), []);

  return {
    ...response,
    refetchQuery: doQuery
  };
};

export const useRequestAll = (
  QUERY: string,
  keys: Array<string>,
  variables?: Object,
  isPaginated?: boolean,
  endpoint?: string
): { loading: boolean; data: any; error: any; refetchQuery: Function } => {
  const [response, setResponse] = useState({
    loading: true,
    error: null as null | any,
    data: null as null | any,
    nextToken: ''
  });

  const doQuery = (nextToken?: string | null, previousResponse?: any) => {
    request(
      QUERY,
      {
        ...variables,
        nextToken
      },
      endpoint
    )
      .then((res) => {
        const nextTokenResult = path(['data', ...keys, 'nextToken'], res) as
          | string
          | null;
        const items = [
          ...((path(['data', ...keys, 'items'], previousResponse) ||
            []) as Array<any>),
          ...((path(['data', ...keys, 'items'], res) || []) as Array<any>)
        ];

        const mergedResponse = set(
          lensPath(['data', ...keys, 'items']),
          items
        )(res) as any;

        setResponse({ ...mergedResponse, nextToken: nextTokenResult });

        if (nextTokenResult && !isPaginated) {
          return doQuery(nextTokenResult, mergedResponse);
        }
      })
      .catch((error) => {
        setResponse({
          data: error.data,
          loading: false,
          error,
          nextToken: ''
        });
      });
  };

  useEffect(() => doQuery(), []);

  return {
    ...response,
    loading: response.loading
      ? response.loading
      : response.nextToken
      ? true
      : false,
    refetchQuery: (nextToken?: string, previousResponse?: any) =>
      doQuery(nextToken, previousResponse)
  };
};
