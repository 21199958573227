import * as React from 'react';
import {Alert as MuiAlert} from '@mui/material';

interface AlertProps {
  severity: 'warning' | 'error' | 'success' | 'info';
  children: string | JSX.Element[] | JSX.Element;
}

export const Alert = (props: AlertProps) => (
  <MuiAlert severity={props.severity}>{props.children}</MuiAlert>
);
