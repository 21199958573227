const sanitizeArray = (arr) =>
  arr
    .filter((item) => {
      return item !== '';
    })
    .map((item) => {
      if (Array.isArray(item)) {
        return sanitizeArray(item);
      }

      if (typeof item === 'object') {
        return sanitizeData(item);
      }

      return item;
    });

export const sanitizeData = (obj) => {
  return Object.keys(obj).reduce((prev, key) => {
    if (obj[key] === '') {
      return prev;
    }

    if (typeof obj[key] === 'function') {
      return prev;
    }

    if (Array.isArray(obj[key])) {
      return {
        ...prev,
        [key]: sanitizeArray(obj[key])
      };
    }

    if (typeof obj[key] === 'object' && obj[key]) {
      return {
        ...prev,
        [key]: sanitizeData(obj[key])
      };
    }

    return {
      ...prev,
      [key]: obj[key]
    };
  }, {});
};
