import styled from 'styled-components';
import { styles } from '../styles';

export const NumberBullet = styled.div`
  background-color: #1e86ff;
  color: #fff;
  border-radius: 50%;
  font-family: ${styles.defaultFont};
  font-size: 14px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
