import * as React from 'react';

export const Shifter = (props: {
    left?: number | string
    top?: number | string
    children?: JSX.Element[] | JSX.Element | string
}) => (<div style={{ position: 'relative', left: props.left || 0, top: props.top || 0}}>
    {props.children}
</div>);

export const Sizer = (props: {
    width?: number | string
    height?: number | string
    children?: JSX.Element[] | JSX.Element | string
}) => (<div style={{ width: props.width || '100%', height: props.height || '100%' }}>
    {props.children}
</div>);