import { request } from '@/api';


const QUERY_APP = require('../graphql/queries/GET_APP_BY_SLUG.gql');

export async function getAppBySlug(
  slugName: string,
  setAppId?: React.SetStateAction<any>,
) {
  const appRes = await request(QUERY_APP, {
    appId: slugName
  });
  const appId =
    appRes?.data?.getAppBySlug?.id as string;
  if (appId) {
    setAppId(appId);
    return appId;
  }
  return undefined;
}
