import * as React from 'react';
import { ModalProps } from './ModalTypes';
import styled from 'styled-components';
import { getObjectKey } from '@/utils/getObjectKey';
import { FlexCol } from '../../Layout';
import { Button } from '../Button';
import { useTranslateKey } from '@/utils/translateKey';

export const Modal = (props: ModalProps) => {
  const { app, backgroundColor } = props
  React.useEffect(() => {
    const onEscapePress = (e: any) => {
      if (e.key === 'Escape') {
        props.handleClose();
      }
    };
    window.addEventListener('keydown', onEscapePress, false);
    return () => window.removeEventListener('keydown', onEscapePress, false);
  });

  React.useEffect(() => {
    const modal = document.querySelector('#modalWindow');
    modal?.addEventListener(
      'click',
      () => {
        props.setUnsavedChanges && props.setUnsavedChanges(true);
      },
      { once: true }
    );
  }, []);

  return (
    <Container
      data-testid={getObjectKey('data-testid', props) || 'modal'}
      onClick={() => {
        if (props.handleBackgroundClick) {
          props.handleBackgroundClick();
        } else {
          props.handleClose();
        }
      }}
    >
      <ContentCenterer>
        <ContentContainer
          id='modalWindow'
          style={{backgroundColor}}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <FlexCol>
            <ContentMain
              style={{
                height: props.height,
                width: props.width
              }}
            >
              {props.children}
            </ContentMain>
            <Button
              variant='outlined'
              style={{ color: app?.theme?.secondaryButtonTextStyle?.color, backgroundColor: app?.theme?.secondaryButtonBackgroundColor, borderColor: app?.theme?.secondaryButtonOutline }}
              onClick={props.handleClose}
            >
              {useTranslateKey('Close')}
            </Button>
          </FlexCol>
        </ContentContainer>
      </ContentCenterer>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
`;

const ContentCenterer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const ContentContainer = styled.div`
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 12px;
  position: relative;
`;

const ContentMain = styled.div`
  overflow: auto;
  max-height: 80vh;
`;

export const ModalPadding = styled.div`
  width: 95%;
`;