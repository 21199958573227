import React, { useEffect, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { InputPropTypes } from './InputTypes';
import styled from 'styled-components';
import CustomTextField from './CustomTextField'; // Import the custom TextField

export const Input: React.FC<InputPropTypes> = (props) => {
  const [value, setValue] = useState(props.value);
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    if (changed && props.value !== value) {
      setValue(props.value);
    }
  }, [props.value, changed]);

  useEffect(() => {
    if (props.value !== value) {
      setValue(props.value);
    }
  }, [props.value]);

  const inputElementProps: Omit<InputPropTypes, 'onFocus' | 'onBlur' | 'onChange'> = {
    maxLength: props.maxLength,
    type: props.type,
    min: props.min,
    max: props.max,
    style: {
      ...props.style,
      borderRadius: props.hideVerticalPadding ? 0 : '5px',
      paddingTop: props.hideVerticalPadding ? 0 : undefined,
      paddingBottom: props.hideVerticalPadding ? 0 : undefined
    }
  };

  return (
    <InputContainer
      className='inputContainer'
      style={{
        borderColor: props.error ? '#F00' : '#D8D8D8',
        borderWidth: props.error ? 2 : 1,
        width: props.width || '100%',
        minWidth: '50px',
        ...props.style,
        borderRadius: '5px'
      }}
    >
      <CustomTextField
        {...props as Omit<InputPropTypes, 'onValueChange' | 'size' | 'color' | 'prefix' | 'maxLength'>}
        value={value}
        variant={props.variant || 'outlined'}
        onKeyPress={props?.onKeyPress}
        data-testid={props['data-testid'] || 'input'}
        fullWidth={props.width ? false : true}
        inputProps={inputElementProps}
        name={props.name}
        InputLabelProps={props.InputLabelProps}
        InputProps={{
          style: props.style,
          readOnly: Boolean(!!props.readOnly),
          startAdornment: props.prefix ? (
            <InputAdornment position='start'>{props.prefix}</InputAdornment>
          ) : undefined,
          endAdornment:
            props.suffix && props.suffix !== 'character_counter' ? (
              <InputAdornment position='end'>{props.suffix}</InputAdornment>
            ) : props.suffix === 'character_counter' &&
              typeof value !== 'number' ? (
              <InputAdornment position='end'>{`${value?.length || 0}/${
                props.maxLength
              }`}</InputAdornment>
            ) : undefined
        }}
        onBlur={(_e) => {
          if (props.onValueChange && props.value !== value) {
            props.onValueChange(value);
            setChanged(true);
          }
          if (props.onBlur) {
            props.onBlur(value);
          }
        }}
        onChange={(e) => {
          if (props.onChange) {
            props.onChange(e);
          }
          setValue(e.target.value);
        }}
        onFocus={() => {
          props.onFocus ? props.onFocus() : null;
          setChanged(false);
          if (props.onValueChange && props.value !== value) {
            props.onValueChange(value);
          }
        }}
        outlineColor={props.outlineColor}
      />
    </InputContainer>
  );
};

const InputContainer = styled.div`
  input {
    background: #fff;
    font-family: Arial, sans-serif;
  }
`;
