import styled from 'styled-components';

export const Title = styled.p`
  font-family: Inter;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  padding-right: 9px;
`;

export const SubTitle = styled.p`
  font-family: Inter;
  color: #7a7a7c;
  line-height: 24px;
  font-weight: 500;
  font-size: 16px;
`;

export const P = styled.p`
  font-family: Inter;
  line-height: 20px;
  font-weight: 400;
  font-size: 16px;
`;

export const PLight= styled.p`
  font-family: Inter;
  line-height: 20px;
  font-weight: 400;
  color: #7a7a7c;
  font-size: 14px;
`;
