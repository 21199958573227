import * as React from 'react';
import styled from 'styled-components';
import { P } from '../../typography';

const { useEffect } = React;

export const Timer = (props: {
  time: number;
  setTime: React.SetStateAction<any>;
  fontSize?: string;
  color: string
}) => {
  const { time, setTime, color } = props;
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (time > 0) {
        setTime(time - 1);
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [time]);
  const seconds = time % 60;

  const timer = time < 0 ? 0 : time

  return (
    <>
      {isNaN(time) ? null : (
        <TimerContainer>
          <P style={{ color, fontSize: props.fontSize ?? '28px' }}>
            {Math.floor(timer / 60)}:
            {seconds < 10 ? `0${Math.floor(seconds)}` : Math.floor(seconds)}
          </P>
        </TimerContainer>
      )}
    </>
  );
};

const TimerContainer = styled.div`
  padding-top: 5px;
  padding-right: 10px;
`;
