import * as React from 'react';
import { delay } from '@/utils/delay';
import styled from 'styled-components';
import { P } from '../../typography';
import { FlexRowBetween } from '../Layout';
import { Timer } from '../Timer';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AppData } from '@/types/loginTypes';

const Menu = styled.div`
  width: 40%;
  height: 100%;
  position: fixed;
  bottom: 0;
  right: 0px;
  z-index: 1001;
  box-shadow: 0px 4px 14px 5px rgba(165, 165, 165, 0.25);
  backdrop-filter: blur(2px);
  overflow: hidden;
`;

const SlidingMenuContainer = styled.div`
  .slide {
    -webkit-animation: slide 0.5s forwards;
    -webkit-animation-delay: 2s;
    animation: slide 0.5s forwards;
  }

  .slide-out {
    -webkit-animation: slide-out 0.5s forwards;
    -webkit-animation-delay: 2s;
    animation: slide-out 0.5s forwards;
  }

  @-webkit-keyframes slide {
    0% {
      -webkit-transform: translateX(100%);
    }
    100% {
      -webkit-transform: translateX(0%);
    }
  }

  @keyframes slide {
    0% {
      -webkit-transform: translateX(100%);
    }
    100% {
      -webkit-transform: translateX(0%);
    }
  }

  @keyframes slide-out {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(100%);
    }
  }

  @-webkit-keyframes slide-out {
    0% {
      -webkit-transform: translateX(0%);
    }
    100% {
      -webkit-transform: translateX(100%);
    }
  }
`;

export const SlidingMenu = (props: {
  isOpen: Boolean;
  children: any;
  onClose: Function;
  setIsOpen: React.SetStateAction<any>;
  title: String;
  time?: number;
  setTime?: React.SetStateAction<any>;
  app: AppData;
}) => {
  const { app } = props;
  async function closeMenu(e?: any) {
    props.setIsOpen(false);
    // allow slide to happen before closing sliding menu
    await delay(0.5);
    props.onClose(e);
  }
  return (
    <ClickAwayListener onClickAway={(e) => closeMenu(e)}>
      <SlidingMenuContainer>
        <Menu
          className={props.isOpen ? 'slide' : 'slide-out'}
          style={{ backgroundColor: app?.theme?.mainBackgroundColor }}
        >
          <SlidingMenuTopBar>
            <FlexRowBetween>
              <div style={{ padding: '25px' }}>
                <ArrowBackIcon
                  onClick={async () => closeMenu()}
                  style={{
                    color: app?.theme?.navBarIconColor
                  }}
                />
              </div>
              {props.time !== undefined && (
                <Timer time={props.time} setTime={props.setTime} color={app?.theme?.linkTextStyle?.color} />
              )}
            </FlexRowBetween>
            <MenuTitle style={{ color: app?.theme?.header2TextStyle?.color }}>
              {props.title}
            </MenuTitle>
          </SlidingMenuTopBar>
          {props.children}
        </Menu>
      </SlidingMenuContainer>
    </ClickAwayListener>
  );
};

const SlidingMenuTopBar = styled.div`
  font-weight: 600;
  width: 100%;
  padding: 25px 0px;
  text-align: center;
  margin-right: 10%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  box-shadow: 0px 4px 4px rgba(192, 192, 192, 0.25);
`;

const MenuTitle = styled(P)`
  font-size: 28px;
  position: absolute;
  width: fit-content;
  margin: auto;
  left: 0;
  right: 0;
  top: 50px;
`;
