import * as React from 'react';
import { FetchErrorProps } from './FetchErrorTypes';
import { Title } from '../../mobile/Typography';
import styled from 'styled-components';
import { ErrorMessage } from '../ErrorMessage';
import { formatGqlErrors } from '@/utils/formatGqlErrors';
import Image from 'next/image';

const { useEffect } = React;

export const FetchError = (props: FetchErrorProps) => {
  useEffect(() => {
    if (props.errorObject) {
      console.warn('warn', props.errorObject);
    }
  }, []);
  const message =
    props.message ||
    'We are currently experiencing technical difficulties but our team is working on it. Please check back soon!';

  return (
    <Container
      style={{
        height: props.fullPage ? '100%' : undefined
      }}
    >
      <Image
        height={345}
        width={177}
        alt='Server-Error'
        src='/images/server-error.png'
      />
      <Description data-testid='title'>{message}</Description>
      {props.errorObject &&
        formatGqlErrors(props.errorObject).map((error, index) => (
          <ErrorMessage key={error + index}>{error}</ErrorMessage>
        ))}
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Description = styled(Title)`
  font-size: 20px;
  color: #6f7287;
  max-width: 552px;
  text-align: center;
`;
