import * as React from 'react';

import styled from 'styled-components';
import { View } from '../Primitives';
import { styles } from '../styles';

export const ContentAreaSmall = (props: any) => (
  <ContentAreaVerticalCenter>
    <ContentAreaSmallStyled
      style={{
        width: props.size === 'medium' ? '780px' : '620px'
      }}
    >
      {props.children}
    </ContentAreaSmallStyled>
  </ContentAreaVerticalCenter>
);

export const ContentArea = styled(View)`
  display: flex;
  padding-left: 24px;
  padding-right: 24px;
  flex: 1;

  @media (max-width: ${styles.mobileWidth}) {
    padding-left: 2vw;
    padding-right: 2vw;
  }
`;

export const ContentAreaVertical = styled(ContentArea)`
  flex-direction: column;
`;

export const ContentAreaWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100vw;

  @media (max-width: ${styles.mobileWidth}) {
    max-width: 98vw;
  }
`;

const ContentAreaVerticalCenter = styled(ContentAreaVertical)`
  align-items: center;
`;

const ContentAreaSmallStyled = styled.div`
  width: 620px;
  display: flex;
  flex-direction: column;

  @media (max-width: ${styles.mobileWidth}) {
    max-width: 98vw;
  }
`;
