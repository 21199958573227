import { request } from '@/api';
// import React from 'react';

const STORE_COMPLETED_CART = require('../../graphql/mutations/STORE_COMPLETED_CART.gql');

export async function storeCompletedCart(
  id: string,
  appId: string,
  setCart?: React.SetStateAction<any>
) {
  await request(STORE_COMPLETED_CART, { id, appId });

  if(setCart)setCart(undefined);
  return
}
