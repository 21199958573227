import styled from 'styled-components';
import { styles } from '../styles';

export const MainTitle = styled.h1`
  font-size: 40px;
  padding: 0px;
  margin: 0px;
  margin-top: 24px;
  margin-bottom: 24px;
  font-family: ${styles.defaultFontExtraBold};
  color: ${styles.textColor};
`;
