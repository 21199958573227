import { sanitizeData } from './sanitizeData';
import { omit } from 'ramda';

export const translateEntity = (
  entity: any,
  locale: string,
  defaultLocale: string = 'en'
) => {
  if (entity) {
    const translations = entity.translations;
    const updatedEntity = { ...entity };
    if (translations?.length) {
      const translation =
        entity.translations.find(
          (translation) => translation.locale === locale
        ) ||
        entity.translations.find(
          (translation) => translation.locale === defaultLocale
        );
      if (translation) {
        for (const key of Object.keys(omit(['locale'], translation))) {
          updatedEntity[key] = translation[key] || entity[key];
        }
      }
    }
    if (entity?.eventLocation) {
      updatedEntity.eventLocation = translateEntity(
        entity.eventLocation,
        locale
      );
    }
    if (entity.ticketType) {
      updatedEntity.ticketType = translateTicketItems(
        [entity.ticketType],
        locale
      )[0];
    }
    if (entity.ticketEvent) {
      updatedEntity.ticketEvent = translateEntity(entity.ticketEvent, locale);
    }
    return sanitizeData(updatedEntity);
  }
  return entity;
};

export const translateEntities = (entities: any[], locale: string) => {
  const updatedEntities = [];
  if (entities) {
    for (const entity of entities) {
      updatedEntities.push(translateEntity(entity, locale));
    }
    return updatedEntities;
  }
  return entities;
};

export const translateTicketItems = (ticketItems: any, locale: string) => {
  if (ticketItems) {
    const updatedTicketItems = [];
    for (const ticketItem of ticketItems) {
      const updatedTicketItem = { ...ticketItem };
      const ticketType = ticketItem.ticketType
        ? ticketItem?.ticketType?.variations?.[0]?.ticketType
        : updatedTicketItem?.variations?.[0].ticketType;
      const updatedTicketType = translateEntity(ticketType, locale);
      if (ticketItem.ticketType) {
        updatedTicketItem.ticketType.variations[0].ticketType =
          updatedTicketType;
      } else {
        updatedTicketItem.variations[0].ticketType = updatedTicketType;
      }
      if (ticketItem.ticketEvent) {
        updatedTicketItem.ticketEvent = translateEntity(
          ticketItem.ticketEvent,
          locale
        );
      }
      updatedTicketItems.push(updatedTicketItem);
    }

    return updatedTicketItems;
  }
  return ticketItems;
};

export const translateForm = (forms: any, locale: string) => {
  if (forms) {
    const updatedForms = [];
    for (const form of forms) {
      const updatedForm = { ...translateEntity(form, locale) };
      const updatedRows = updatedForm.rows.map((row) => {
        return {
          ...row,
          items: row.items?.map((item) => translateEntity(item, locale))
        };
      });
      updatedForm.rows = updatedRows;
      const updatedSubmitButton = translateEntity(form.submitButton, locale)
      updatedForm.submitButton = updatedSubmitButton
      updatedForms.push(updatedForm);
    }
    return updatedForms;
  }
  return forms;
};
