import * as React from 'react';
import { StateManagerTypes } from './StateManagerTypes';
import { FetchError, Loading } from '../';
import { WebContentArea } from '../../web';

export const StateManager = ({
  loading,
  error,
  CustomError,
  children
}: StateManagerTypes) =>
  loading ? (
    <Loading />
  ) : error ? (
    !!CustomError ? (
      <>{CustomError}</>
    ) : (
      <DefaultError error={error} />
    )
  ) : (
    <>{children}</>
  );

const DefaultError = ({ error }) => (
  <WebContentArea>
    <FetchError errorObject={error} />
  </WebContentArea>
);
