import Image from 'next/image';
import * as React from 'react';
import { IconPropsType } from './IconTypes';

export const Icon = React.forwardRef((props: IconPropsType, ref: any) => {
  const imageSrc = `/images/${props.type === 'png' ? '' : 'icons/'}${
    props.name
  }.${props.type || 'svg'}`;
  return (
    <Image
      data-testid={props['data-testid'] || `${props.name}Icon`}
      ref={ref}
      title={props.title || props.name}
      src={imageSrc}
      width={props.width}
      height={props.height}
      alt={`${props.name} Image`}
      style={{
        pointerEvents: 'none',
        height: `${props.height}px`,
        width: `${props.width}px`,
        display: props.inline ? 'inline' : undefined,
        ...props.style
      }}
    />
  );
});
