import { request } from '@/api';
import { CartType } from '@/types/cartTypes';
import React from 'react';
import { removeCart } from './removeCart';

const GET_CART = require('../../graphql/queries/GET_CART.gql');

export async function getCart(
  id: string,
  appId: string,
  showCart: Boolean,
  appName: string,
  setCart?: React.SetStateAction<any>
) {
  const cartRes = await request(GET_CART, {
    id,
    appId
  });
  const cartData = (cartRes?.data?.getCart as CartType) ?? undefined;
  if(cartData){
    const now = Date.now() / 1000;
    const cartTimer = cartRes?.data?.getApp?.settings?.cartTimer;
    if (cartData.date < now - cartTimer * 60 && !showCart) {
      removeCart(cartData.id, appId, appName);
      return undefined;
    } else if (setCart && cartData) {
      setCart(cartData);
      return cartData;
    }
  }
  return cartData;
}
