import { request } from '@/api';
import React from 'react';

const REMOVE_CART = require('../../graphql/mutations/REMOVE_CART.gql');

export async function removeCart(
  id: string,
  appId: string,
  appName: string,
  setCart?: React.SetStateAction<any>
) {
  await request(REMOVE_CART, { id, appId, appName });
  if(setCart)setCart(undefined);
  localStorage.removeItem('cartSessionId')
  return;
}
  