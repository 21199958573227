import { request } from '@/api';
import { SelectedTicketsType } from '@/types/cartTypes';
import * as React from 'react';
import { v4 as uuid } from 'uuid';

const CREATE_CART = require('../../graphql/mutations/CREATE_CART.gql');

export async function createCart(
  appId: string,
  selectedTickets: SelectedTicketsType,
  setCart: React.SetStateAction<any>,
  refetchQuery: Function,
  dragonflyAppId: string,
  subAppId: string,
  userId: string,
  citizenAppId: string,
  appName: string
) {
  const cartItems = selectedTickets.map((inv) => ({
    ...inv,
    itemId: uuid()
  }));

  const id = uuid();

  const input = {
    id,
    subAppId,
    dragonflyAppId,
    userId,
    citizenAppId,
    appId,
    cartItems
  };

  try {
    const newCart = await request(CREATE_CART, { appId, appName, input });
    if (!newCart.data.createCart.success) {
      refetchQuery();
      throw new Error(newCart.data.createCart.error);
    } else setCart(newCart.data?.createCart?.cart);
    if (userId === 'guest') localStorage.setItem('cartSessionId', id);
    await refetchQuery();
    return newCart;
  } catch (error) {
    return { error };
  }
}
