import * as React from 'react';

import {
  FormControl,
  Select as MaterialSelect,
  MenuItem
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { SelectPropTypes } from './SelectTypes';
import styled from 'styled-components';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FlexRowBetween, FlexRowCenter } from '../Layout';
import { Icon } from '../Icon';
import { styles } from '../../styles';
import { P, PLightPlaceholder } from '../../typography';
import Image from 'next/image';

export const Select = (props: SelectPropTypes) => {
  const hasValueInOptions = !!props.options?.find(
    (option) => option.id === props.value
  );
  const value =
    (props.value || props.value === 0) && hasValueInOptions ? props.value : '';
  const StyledSelect = withStyles(() => ({
    root: {
      padding: props.padding
    },
    icon: {
      fill: props.fontColor
    }
  }))(MaterialSelect) as any;

  return (
    <SelectContainer
      className='inputContainer'
      style={{ width: props.width }}
      fontColor={props.fontColor}
    >
      <FormControl
        fullWidth={!!props.fullWidth}
        variant={props.hideBorder ? 'standard' : 'outlined'}
      >
        <StyledSelect
          IconComponent={KeyboardArrowDownIcon}
          startAdornment={props.prefix}
          endAdornment={props.suffix}
          style={{ height: props.height }}
          displayEmpty
          disableUnderline={!!props.disableUnderline}
          fullWidth={!!props.fullWidth}
          disabled={props.disabled}
          value={value}
          renderValue={props.renderValue}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            if (props.onChange) {
              props.onChange(e);
            }
            if (props.onValueChange) {
              props.onValueChange(e.target.value);
            }
          }}
        >
          {props.placeholder && (
            <MenuItem value='' disabled>
              <PLightPlaceholder>{props.placeholder}</PLightPlaceholder>
            </MenuItem>
          )}
          {(props.options || []).map((option) => (
            <MenuItem value={option.id} key={option.id}>
              {option.img ? (
                <FlexRowBetween>
                  <div style={{ width: '70%' }}>
                    <P style={{ color: props.fontColor ?? 'black' }}>
                      {option.name}
                    </P>
                  </div>
                  <div style={{ width: '30%' }}>
                    <FlexRowCenter>
                      <Image
                        src={option.img}
                        alt={option.name + ' Photo'}
                        style={{ maxHeight: '116px' }}
                      />
                    </FlexRowCenter>
                  </div>
                </FlexRowBetween>
              ) : option.icon ? (
                <>
                  {option.id !== value && (
                    <Icon name={option.icon} width={15} height={15}/>
                  )}
                  <P
                    style={{
                      marginLeft: '10px',
                      color: props.fontColor ?? 'black',
                      fontSize: props.fontSize ?? '16px'
                    }}
                  >
                    {option.name}
                  </P>
                </>
              ) : (
                <P
                  style={{
                    color: props.fontColor ?? 'black',
                    fontSize: props.fontSize ?? '16px'
                  }}
                >
                  {option.name}
                </P>
              )}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </SelectContainer>
  );
};

const SelectContainer = styled.div<{ fontColor?: string }>`
  flex: 1;
  display: flex;
  flex-direction: row;
  .MuiInput-underline:before {
    border-bottom: 2px solid
      ${(props) => props.fontColor ?? 'rgba(0, 0, 0, 0.42)'};
  }
  .MuiInput-underline:after {
    border-bottom: 2px solid ${(props) => props.fontColor ?? '#3f51b5'};
  }
  select {
    font-family: ${styles.defaultFont};
  }
`;
