import styled from 'styled-components';
import { styles } from '../styles';

export const Title = styled.h2`
  font-size: 20px;
  font-family: ${styles.defaultFontSemiBold};
  color: ${styles.textColor};
  margin: 0px;
  margin-top: 12px;
  margin-bottom: 12px;
`;

export const SubTitle = styled.h2`
  font-size: 16px;
  font-family: ${styles.defaultFontSemiBold};
  font-weight: 500;
  color: #3f4658;
  margin: 12px 0;
`;

export const LargeTitle = styled.h2`
  font-size: 32px;
  font-family: ${styles.defaultFontBlack};
  color: ${styles.textColor};
  margin: 0;
  margin-top: 30px;
  margin-bottom: 6px;
  letter-spacing: 0.2px;

  @media (max-width: 599px) {
    font-size: 28px;
  }
`;
