import * as React from 'react';
import { ButtonPropType } from './ButtonTypes';
import styled from 'styled-components';
import { Button as MuiButton } from '@mui/material'
import { Spacer } from '../Spacer';
import { withTheme } from '@/withTheme';

const ButtonDisplay = (props: ButtonPropType) => {
  const buttonType = props.buttonType || 'primary';

  const variant = {
    primary: 'contained',
    outlined: 'outlined',
    outlinedSecondary: 'outlined',
    transparent: undefined
  };

  const size = {
    link: 'small',
    actionDelete: 'small',
    actionEdit: 'small',
    primary: 'medium',
    outlined: 'medium'
  };

  const color = {
    primary: 'primary',
    actionDelete: 'default',
    removeButton: 'default',
    link: 'secondary',
    outlined: 'primary',
    outlinedSecondary: 'secondary'
  };

  return (
    <MuiButton
      endIcon={props.endIcon}
      disableFocusRipple={false}
      disableRipple={false}
      style={
        props.buttonType === 'outlined'
          ? {
              border: '2px solid',
              boxSizing: 'border-box',
              boxShadow: 'none',
              ...props.style
            }
          : { boxShadow: 'none', ...props.style }
      }
      type={props.type || 'button'}
      disabled={props.disabled}
      variant={props.variant || variant[buttonType] || 'text'}
      color={color[buttonType] || props.color || 'primary'}
      size={size[buttonType] || 'large'}
      data-testid={props['data-testid'] || 'button'}
      fullWidth={props.fullWidth}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        if (props.onClick) {
          e.preventDefault();
          props.onClick(e);
        }
      }}
    >
      <span
        style={{
          color: buttonType === 'actionDelete' ? '#F8001F' : undefined,
          display: 'flex',
          alignItems: 'center',
          textTransform: 'none'
        }}
      >
        {props.icon || null}
        {props.icon && <Spacer width={6} />}
        {props.children}
        {props.isLoading && (
          <Loading>
            <LoadingBar />
          </Loading>
        )}
      </span>
    </MuiButton>
  );
};

const Loading = styled.div`
  background-color: #d6dae5;
  height: 2px;
  overflow: hidden;
  width: 100%;
  left: 50%;
  margin: 0;
  position: absolute;
  bottom: -1px;
  transform: translate(-50%, -50%);
`;

const LoadingBar = styled.div`
  @keyframes side2side {
    0%,
    100% {
      transform: translateX(-50%);
    }
    50% {
      transform: translateX(150%);
    }
  }
  animation: side2side 2s ease-in-out infinite;
  background-color: #4a90e2;
  height: 100%;
  position: absolute;
  width: 50%;
`;

export const Button = withTheme(ButtonDisplay)
